import React, { useContext } from 'react';
import { GlobalContext } from './GlobalContext';

const AuthPage = (props) => {
  
    const {changeUserId} = useContext(GlobalContext);
      
    return(
        <>
            <div className='flex flex-row w-full mt-80'>
                <div className="mt-2 w-full flex mx-8">
                    
                        <select
                            id="trierPar"
                            name="trierPar"
                            onChange={(e)=>{changeUserId(e.target.value)}}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                        >
                            <option value="">Choisir une personne</option>
                            <option value="1">Valentin</option>
                            <option value="2">Océane</option>
                        </select>
                    
                </div>
            </div>
        </>
    )
}

export default AuthPage
