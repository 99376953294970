import React, { useState, useEffect,useContext } from 'react';
import BottomTab from './BottomTab'
import { Switch } from '@headlessui/react'
import { GlobalContext } from './GlobalContext';
import lodash from 'lodash'
import ReactApexChart from "react-apexcharts";
import moment from 'moment'

const Stats = (props) => {

    const [stats,setStats] = useState([])

    const [enabled, setEnabled] = useState(false)

    const { language,setLanguage,movies,changeUserId} = useContext(GlobalContext);
    
    const [series,setSeries] = useState([])
    const [seriesGenres,setSeriesGenres] = useState([])
    const [donutSeries,setDonutSeries] = useState([])

    const options= {
        chart: {
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type:"number",
            categories: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"]
        },
        
    }

    const DonutsOptions = {
        chart: {
          type: 'donut',
        },
        legend: {
            show:false,
        },
        labels:["Films","Séries"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: "100%"
            },
           
          }
        }],
        plotOptions: {
            pie: {
              donut: {
                labels: {
                      show: true,
                      name: {
                          show: true,
                          fontSize: '22px',
                          fontFamily: 'Rubik',
                          color: '#dfsda',
                          offsetY: -10
                      },
                      value: {
                          show: true,
                          fontSize: '16px',
                          fontFamily: 'Helvetica, Arial, sans-serif',
                          color: undefined,
                          offsetY: 16,
                          formatter: function (val) {
                              return val
                          }
                      },
                      total: {
                          show: true,
                          label: 'Total',
                          color: '#373d3f',
                          formatter: function (w) {
                              return w.globals.seriesTotals.reduce((a, b) => {
                                  return a + b
                              }, 0)
                          }
                      }
                  }
              }
            }
          },
    }

    const [optionsGenres, setOptionsGenres] = useState({
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: [],
          position: 'bottom',
          labels:{
            rotate:-70
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }
        }
      })

      useEffect(() => {
        fetch(`https://api.themoviedb.org/3/genre/tv/list?api_key=5c47e1d0868537d5542fcede64c202db&language=${language}`,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
           console.log(json)
        }).catch((error)=>{
            console.log(error)
        })
      }, []);


    useEffect(() => {
        if(language == "fr-FR"){
            setEnabled(false)
        }else{
            setEnabled(true)
        }
    }, []);


    useEffect(() => {
        let films = {
            name: 'Films',
            data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
        let series = {
            name: 'Séries',
            data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
        let donuts = [0,0]
        let categories = []
        let categGenres = []


        if(movies){
            lodash.forEach(movies,(m)=>{
                if(m.media_type === "movie"){
                    lodash.forEach(m.genres,(genre)=>{
                        if(!categories.includes(matchGenre(genre.name))){
                            categories.push(matchGenre(genre.name))
                            categGenres.push(1)
                        }else{
                            let i = lodash.findIndex(categories,(c)=>{
                                return matchGenre(c) === matchGenre(genre.name)
                            })
                            
                            categGenres[i]+=1
                        }
                    })
                    films.data[moment(m.dateAjout, "DD-MM-YYYY HH:mm:ss").month()] = films.data[moment(m.dateAjout, "DD-MM-YYYY HH:mm:ss").month()] + 1
                    donuts[0] = donuts[0]+1
                }else{
                    lodash.forEach(m.genres,(genre)=>{
                        if(!categories.includes(matchGenre(genre.name))){
                            categories.push(matchGenre(genre.name))
                            categGenres.push(1)
                        }else{
                            let j = lodash.findIndex(categories,(c)=>{
                                return matchGenre(c) === matchGenre(genre.name)
                            })
                            categGenres[j]+=1
                        }
                    })
                    series.data[moment(m.dateAjout, "DD-MM-YYYY HH:mm:ss").month()] = series.data[moment(m.dateAjout, "DD-MM-YYYY HH:mm:ss").month()] + 1
                    donuts[1] = donuts[1]+1
                }
            })
            setSeriesGenres([{name: 'Catégories',data: categGenres}])
            setOptionsGenres({...optionsGenres,xaxis:{...optionsGenres.xaxis,categories:categories}})
        }
        setSeries([films,series])
        setDonutSeries(donuts)
    }, [movies]);

    useEffect(() => {
        localStorage.setItem("search","")
    }, []);

    useEffect(() => {
        if(movies){
            let movie = 0
            let serie = 0
            lodash.forEach(movies,(m)=>{
                if(m.media_type == 'movie'){
                    movie+=1
                }else{
                    serie+=1
                }
            })
            let tab = []
            tab.push({ name: 'Nombre de films', stat: movie })
            tab.push({ name: 'Nombre de série', stat: serie })
            setStats(tab)
        }
    }, [movies]);

    const matchGenre = (genre) => {
        switch (genre) {
            case "Action":
            case "Action & Adventure":
                return "Action"
                break;
            case 'Animation':
                return "Animation"
                break;
            case 'Aventure':
            case "Adventure":
                return "Aventure"
                break;
            case 'Animation':
                return "Animation"
                break;
            case 'Comédie':
            case "Comedy":
                return "Comédie"
                break;
            case 'Crime':
                return "Crime"
                break;
            case 'Documentaire':
                return "Documentaire"
                break;
            case 'Drame':
            case "Drama":
                return "Drame"
                break;
            case 'Familial':
            case "Family":
                return "Familial"
                break;
            case 'Fantastique':
            case "Fantasy":
                return "Fantastique"
                break;
            case 'Histoire':
                return "Histoire"
                break;
            case 'Kids':
                return "Kids"
                break;
            case 'Horreur':
            case "Horror":
                return "Horreur"
                break;
            case 'Musique':
                return "Musique"
                break;
            case 'Mystère':
                return "Mystère"
                break;
            case 'News':
                return "News"
                break;
            case 'Romance':
                return "Romance"
                break;
            case 'Reality':
                return "Reality"
                break;
            case 'Science-Fiction':
            case "Science Fiction":
            case 'Science-Fiction & Fantastique':
                return "Science-Fiction"
                break;
            case 'Soap':
                return "Soap"
                break;
            case 'Talk':
                return "Talk"
                break;
            case 'Téléfilm':
                return "Téléfilm"
                break;
            case 'Thriller':
                return "Thriller"
                break;
            case 'Guerre':
            case 'War & Politics':
                return "Guerre"
                break;
            case 'Western':
                return "Western"
                break;
            default:
                console.log(genre)
                break;
        }
    }

    const changeLanguage = ()=> {
        if(enabled){
            setLanguage('fr-FR')
            localStorage.setItem("language",'fr-FR')
            setEnabled(false)
        }else{
            setLanguage('en')
            localStorage.setItem("language",'en-EN')
            setEnabled(true)
        }
    }

    return(
        <>
            <BottomTab/>
            <div className='flex justify-center mt-4'>
                <span className='font-bold mx-2'>Francais</span>
                <Switch
                    checked={enabled}
                    onChange={changeLanguage}
                    className={"relative inline-flex items-center h-6 rounded-full w-11 bg-back"}
                >
                    <span
                        className={`${
                        enabled ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                    />
                </Switch>
                <span className='font-bold mx-2'>Anglais</span>
            </div>
            <div className='px-6 pb-2 mt-4 mb-10'>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {stats.map((item) => (
                        <div key={item.name} className="px-4 py-5 bg-white shadow-md flex flex-col justify-center items-center rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                        </div>
                    ))}
                </dl>
            </div>
            <div id="chart1" className='my-5'>
                <ReactApexChart options={options} series={series} type="area" height={350} />
            </div>
            <div id="chart2" className='my-5'>
                <ReactApexChart options={DonutsOptions} series={donutSeries} type="donut" height={350} />
            </div>
            <div id="chart3" className='my-5'>
                <ReactApexChart options={optionsGenres} series={seriesGenres} type="bar" height={350} />
            </div>
            <div className='flex justify-center w-full mt-2 mb-20'>
                <div
                    onClick={()=>changeUserId("")}
                    className="inline-flex cursor-pointer items-center px-20 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-delete  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                    Me déconnecter
                </div>
            </div>
        </>
    )
}

export default Stats
