import React, { useState, useEffect,useContext } from 'react';
import { useParams } from 'react-router';
import { Link,useLocation } from 'react-router-dom';
import BottomTab from './BottomTab'
import { GlobalContext } from './GlobalContext';
import moment from 'moment';

const Movie = (props) => {

    let {id} = useParams()
    const location = useLocation()
    const [movie,setMovie] = useState(null)
    const {language,isInMyList,addMovie,deleteMovie,getViewCounter,addViewCounter} = useContext(GlobalContext);

    useEffect(() => {
        getInfos()
    }, []);

    const getInfos = () => {
        fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=5c47e1d0868537d5542fcede64c202db&language=${language}`,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            setMovie(json)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getDuree = (time) => {
        let res = 0
        while (time >= 60) {
            res+=1
            time-= 60
        }
        return `${res}h${time}`
    }

    if(!movie){
        return(
            <div>Chargement</div>
        )
    }


    return(
        <>
            <BottomTab/>

            <Link  to={{
                    pathname: location.state.backPath,
                    state: { search: location.state.search,trierPar:location.state.trierPar}
                }}>
                    <div className='grid grid-cols-5 mt-4 ml-4'>
                        <span className='bg-back text-primary p-0.5 rounded-md text-center'>Retour</span>
                    </div>
                </Link>
                <div className='p-4 flex flex-col mb-20'>
                <span className='font-bold text-2xl '>{movie.title ? movie.title : null}</span>
                <span className='font-medium text-lg mb-2'>{movie.tagline ? movie.tagline : null}</span>
                <div className='grid grid-cols-2 lg:grid-cols-5 gap-4'>
                        <div className=" group-hover:opacity-75 block w-full aspect-w-10 aspect-h-15 rounded-t-lg ">
                            {movie.poster_path ? 
                                <img src={"https://image.tmdb.org/t/p/w1280"+movie.poster_path} alt="" className=" rounded-lg pointer-events-none "/>
                            :
                                <div className='border-t border-l border-r border-dashed border-secondary rounded-t-lg'></div>
                            }
                        </div>
                        <div className='flex flex-col mt-2'>
                            <span><u className='font-semibold'>Sortie</u>: {movie.release_date ? moment(movie.release_date).format("DD MMM YYYY"): "Inconnu"}</span>
                            {movie.runtime ?
                                <span>
                                    <u className='font-semibold'>Durée</u>: {getDuree(movie.runtime)} 
                                </span> 
                            : null}
                            {movie.vote_average ? 
                                <span>
                                    <u className='font-semibold'>Note</u>: {movie.vote_average} / 10
                                </span>
                            : null}
                            
                            <div >
                            {isInMyList(movie.id) ?
                            <>
                                <span onClick={()=>deleteMovie(movie)} className="cursor-pointer mt-6 inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-red-300 text-red-800">
                                    Supprimer de ma liste
                                </span>
                                <span onClick={()=>addViewCounter(movie.id)} className="cursor-pointer mt-6 inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-blue-300 text-blue-800">
                                    Revu ( Vu {getViewCounter(movie.id)} fois )
                                </span>
                            </>
                                
                            : 
                                <span onClick={()=>addMovie(movie)} className="cursor-pointer mt-6 inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-green-300 text-green-800">
                                    Ajouter à ma liste
                                </span>
                            }
                               
                            </div>
                            <div></div>
                        </div>
                        <div className='col-span-2'>
                            {movie.genres.map((g,i)=>{
                                return(
                                    <>
                                        <span className="mx-1 my-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-300 text-yellow-800">
                                            {g.name}
                                        </span>
                                    </>
                                )
                            })}
                        </div>      
                </div>
                <span className='font-bold text-2xl mt-4'>Résumé :</span>
                <div className='mt-2 p-2 bg-primaryLight bg-opacity-50 rounded-md'>
                    {movie.overview}
                </div>
            </div>
            
        </>
    )
}
export default Movie