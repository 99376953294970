import React, { useState, useEffect,useContext } from 'react';
import BottomTab from './BottomTab'
import lodash from 'lodash'
import { Switch } from '@headlessui/react'
import Lottie from 'react-lottie'
import Empty from '../lotties/empty.json'
import moment from 'moment';
import { Link,useLocation } from 'react-router-dom';
import { GlobalContext } from './GlobalContext';

const Search = (props) => {

    
    const [search,setSearch] = useState("")
    const {language,isInMyList} = useContext(GlobalContext);
    const [resultat,setResultat] = useState([])
    const [infos,setInfos] = useState(null)
    const [page,setPage] = useState(1)
    const [maxPage,setMaxPage] = useState(0)
    const [isSubmit,setIsSubmit] = useState(false)
    const [filter,setFilter] = useState({
        movie:true,
        tv:true
    })

    const [nbResult,setNbResult] = useState(null)

    const optEmpty = {
        loop: true,
        autoplay: true,
        animationData: Empty,
    };

    useEffect(() => {
        localStorage.setItem("search","")
    }, []);


    useEffect(() => {
        setNbResult(null)
        if(search != ""){
            fetchDatas(1)
        }
    }, [filter]);


    const getPourcentage = () => {
        if(maxPage == 0){
            return 0
        }else{
            return (((page-1)*100)/maxPage)
        }
    }

    const getMovies = (page) => {
        fetch(`https://api.themoviedb.org/3/search/movie?language=${language}&api_key=5c47e1d0868537d5542fcede64c202db&page=1&query=${search}&page=${page}`,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            console.log(json)
            //setResultat(json.results)
            let tab 
            if(page == 1){
                tab = []
            }else{
                tab = JSON.parse(JSON.stringify(resultat))
            }
            lodash.forEach(json.results,(r)=>{
                tab.push(r)
            })
            // setNbResult(json.results.length)
            // setTimeout(() => {
            //     setNbResult(null)
            // }, 2500);
            setPage(page+1)
            setResultat(tab)
            setMaxPage(json.total_pages)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getSeries = (page) => {
        fetch(`https://api.themoviedb.org/3/search/tv?language=${language}&api_key=5c47e1d0868537d5542fcede64c202db&page=1&query=${search}&page=${page}`,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            console.log(json)
            //setResultat(json.results)
            let tab 
            if(page == 1){
                tab = []
            }else{
                tab = JSON.parse(JSON.stringify(resultat))
            }
            lodash.forEach(json.results,(r)=>{
                tab.push(r)
            })

            // setNbResult(json.results.length)
            // setTimeout(() => {
            //     setNbResult(null)
            // }, 2500);
            setPage(page+1)
            setResultat(tab)
            setMaxPage(json.total_pages)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getSeriesAndMovies = (page) => {
        fetch(`https://api.themoviedb.org/3/search/multi?language=${language}&api_key=5c47e1d0868537d5542fcede64c202db&page=1&query=${search}&page=${page}`,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            console.log(json)
            let tab 
            if(page == 1){
                tab = []
            }else{
                tab = JSON.parse(JSON.stringify(resultat))
            }
            lodash.forEach(json.results,(r)=>{
                tab.push(r)
            })

            // setNbResult(json.results.length)
            // setTimeout(() => {
            //     setNbResult(null)
            // }, 2500);
            setPage(page+1)
            setResultat(tab)
            setMaxPage(json.total_pages)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const fetchInfos = (id) => {
        fetch(`https://api.themoviedb.org/3/tv/${id}?&language=${language}&api_key=5c47e1d0868537d5542fcede64c202db`,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
           console.log(json)
           setInfos(json)
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    const fetchDatas = (page) => {
        if(filter.movie && filter.tv){
            getSeriesAndMovies(page)
        }else if(filter.movie){
            getMovies(page)
        }else if(filter.tv){
            getSeries(page)
        }else{
            setResultat([])
            setMaxPage(0);
            setPage(1);
        }
    }

    //Fait une recherche automatique apres que la personne ai arrété decrire pendant 1seconde SAUF si elle a appuyer sur rechercher sur le clavier
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if(search != ""){
              if(!isSubmit){
                   fetchDatas(1)
                document.activeElement.blur()
              }
          }
        }, 1000)
    
        return () => clearTimeout(delayDebounceFn)
      }, [search])

    return(
        <>
            <BottomTab/>
            {/*nbResult != null ? <Notification nb={nbResult}/> : null*/}
            
            <div className="px-6 pb-2 mt-4 mb-10">
            
            <form onSubmit={(event)=>{event.preventDefault();setTimeout(()=>{setIsSubmit(false)},1500);setIsSubmit(true);fetchDatas(1);document.activeElement.blur()}}>  
                <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder={"Rechercher un titre"}
                    value={search}
                    onChange={(e)=>{
                        setSearch(e.target.value);
                        setMaxPage(0);
                        setPage(1);
                        setResultat([])
                    }}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
            </form>
            <div className='grid grid-cols-2 gap-4 mt-2 justify-items-center'>
                <div className='flex flex-row'>
                    <Switch
                        checked={filter.movie}
                        onChange={()=>setFilter({...filter,movie:!filter.movie})}
                        className={`${
                            filter.movie ? 'bg-secondaryDark' : 'bg-secondaryLight'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                    >
                    <span
                        className={`${
                        filter.movie ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                    />
                    </Switch>
                    <span className='ml-2 text-gray-600'>Films</span>
                </div>
                <div className='flex flex-row'>

                    <Switch
                    checked={filter.tv}
                    onChange={()=>setFilter({...filter,tv:!filter.tv})}
                    className={`${
                        filter.tv ? 'bg-secondaryDark' : 'bg-secondaryLight'
                    } relative inline-flex items-center h-6 rounded-full w-11`}
                    >
                    <span
                        className={`${
                        filter.tv ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                    />
                    </Switch>
                    <span className='ml-2 text-gray-600'>Séries</span>
                </div>
            </div>

            {resultat.length == 0 ?
                <Lottie
                    options={optEmpty}
                    isClickToPauseDisabled={true}
                    height={500}
                    width={window.innerWidth-100}
                />
            :null}
            
            <div role="list" className="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-6 xl:gap-x-8 mt-4">
                    
                    {resultat.map((r,i)=>{
                            if (filter.movie !== (r.media_type == "movie") && filter.tv !==  (r.media_type == "tv")) {
                                return null;
                            }
                            else if (filter.movie === false && filter.tv === false) {
                                return null;
                            }
                            return(
                                <Link 
                                to={{
                                    pathname: (filter.movie && filter.tv) ? (r.media_type == "movie" ? "/movie/"+r.id : "/serie/"+r.id): filter.movie ? "/movie/"+r.id : "/serie/"+r.id,
                                    state: { search: search,filter:filter,backPath:"/search" }
                                }}>
                                    <div key={i} onClick={()=>fetchInfos(r.id)} className={' group cursor-pointer'}>
                                       
                                        <div className=" group-hover:opacity-75 block w-full aspect-w-10 aspect-h-15 rounded-t-lg ">
                                            {r.poster_path ? 
                                                <img src={"https://image.tmdb.org/t/p/w1280"+r.poster_path} alt="" className=" rounded-t-lg pointer-events-none "/>
                                            :
                                                <div className='border-t border-l border-r border-dashed border-secondary rounded-t-lg'></div>
                                            }
                                        </div>
                                        
                                        <div className="bg-white shadow-lg px-2 py-1 rounded-b-lg pointer-events-none border-t">
                                            <p className='text-sm font-medium text-gray-900 truncate '>{r.title ? r.title : r.name}</p>
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-200 text-pink-800">
                                                {(filter.movie && filter.tv) ?
                                                    (r.media_type == "movie" ? "Film" : "Série")
                                                : filter.movie ? "Film" : "Série"
                                                }
                                            </span>
                                            {isInMyList(r.id) ?
                                                <span className=" mx-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-200 text-green-800">
                                                    Dans ma liste
                                                </span>
                                            :null}
                                            {r.release_date ?
                                                (moment(r.release_date).isAfter(moment()) ? 
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-200 text-purple-800">
                                                    Prochainement
                                                </span> : null)
                                            :
                                               (moment(r.first_air_date).isAfter(moment()) ? 
                                               <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-200 text-purple-800">
                                                    Prochainement
                                                </span> : null)
                                            }
                                           
                                            <div className="mt-1 flex items-center text-sm text-gray-500 ">
                                                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                                </svg>
                                                <p className='truncate'>
                                                    {(filter.movie && filter.tv) ?
                                                        (r.media_type == "movie" ? 
                                                            (r.release_date ? moment(r.release_date).format("D MMMM yyyy")  : "Inconnu")
                                                        : 
                                                            (r.first_air_date ? moment(r.first_air_date).format("D MMMM yyyy")  : "Inconnu")
                                                        )
                                                    : filter.movie ?  
                                                        (r.release_date ? moment(r.release_date).format("D MMMM yyyy")  : "Inconnu") 
                                                    : 
                                                        (r.first_air_date ? moment(r.first_air_date).format("D MMMM yyyy")  : "Inconnu")
                                                    }
                                                </p>
                                            </div>
                                        <div className='flex justify-end'>
                                            <Note note={r.vote_average} />
                                        </div>
                                        </div>
                                    </div>
                                </Link>
                                    
                            )
                        })
               
                    }
                </div>

                {page <= maxPage ?
                    <div onClick={()=>fetchDatas(page)} className="relative mt-4 ">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-secondaryLight"></div>
                        </div>
                        <div className="relative flex justify-center ">
                            <div className="px-2 pointer-events-none cursor-pointer bg-primary text-sm text-gray-600">
                                Voir plus
                            </div>
                        </div>
                </div>
                   
                : (maxPage != 0 && page > maxPage) ?
                <div className="relative mt-4">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-secondaryLight"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-primary text-sm text-gray-500">
                        Fin
                    </span>
                </div>
            </div>
            :
                   null
                }

                 {maxPage != 0 ? 
                    <div className="flex flex-col">
                        <div className="mt-6" aria-hidden="true">
                            <div className="bg-primaryLight rounded-full overflow-hidden">
                                <div className="h-2 bg-secondary rounded-full" style={{width:getPourcentage()+"%"}}></div>
                            </div>
                        
                        </div>
                    </div>
                :null}

                <div role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-6 xl:gap-x-8 mt-4 mb-4">
                    
                    {infos ?
                        infos.seasons.map((r,i)=>{
                            return(
                                <>
                                    <div key={i}>
                                        <div className="group block w-full aspect-w-10 aspect-h-15 rounded-t-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 ">
                                            {r.poster_path ? 
                                                <img src={"https://image.tmdb.org/t/p/w1280"+r.poster_path} alt="" className=" rounded-t-lg pointer-events-none "/>
                                            :null}
                                        </div>
                                        <div className="bg-white shadow-lg p-2 rounded-b-lg pointer-events-none border-t">
                                            <p className='text-sm font-medium text-gray-900 text-center truncate '>{r.name}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
               
                    :null}
                </div>
           
                </div>
        </>
    )
}
export default Search


const Note = ({note}) => {
    //rouge 0-4
    //orange 4-7
    //vert 7-10
    if(note >=0 && note <4 ){
        return(
            <span className="-mb-4 -mr-5 md:-mb-5 md:-mr-5 text-white font-bold text-xs h-7 w-7 md:h-7 md:w-7 rounded-full ring-2 flex justify-center ring-transparent items-center bg-red-400">{note}</span>
        )

    }else if(note >=4 && note <7){
        return(
            <span className="-mb-4 -mr-5 md:-mb-5 md:-mr-5 text-white font-bold text-xs h-7 w-7 md:h-7 md:w-7 rounded-full ring-2 flex justify-center ring-transparent items-center bg-yellow-400">{note}</span>
            )

    }else{
        return(
            <span className="-mb-4 -mr-5 md:-mb-5 md:-mr-5 text-white font-bold text-xs h-7 w-7 md:h-7 md:w-7 rounded-full ring-2 flex justify-center ring-transparent items-center bg-green-400">{note}</span>
            )

    }
}

const Notification = ({nb}) => {
    return( 
        <div className="fixed inset-x-0 bottom-0 mb-20 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">

                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                    <div className="flex items-start">
                    <div className="flex-shrink-0">
                        <svg className="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                        {nb} résultats trouvés !
                        </p>
                        
                    </div>
                    
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}