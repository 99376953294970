import React, { useState, useEffect,useContext } from 'react';
import BottomTab from './BottomTab'
import moment from 'moment'
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie'
import Dino from '../lotties/dino.json'
import { GlobalContext } from './GlobalContext';

const List = (props) => {

    const {movies,trierPar,filter,search,setSearch} = useContext(GlobalContext);
    
    const optDino = {
        loop: true,
        autoplay: true,
        animationData: Dino,
    };

    return(
        <>
            <BottomTab/>
            <div className="px-6 pb-2 mt-4 mb-10">
                <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder={"Rechercher un titre"}
                    value={search}
                    onChange={(e)=>{
                        setSearch(e.target.value);localStorage.setItem("search",e.target.value)
                    }}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
                <div className=" mt-2 grid grid-cols-4">
                    <label htmlFor="country" className="ml-2 flex items-center text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Trier par
                    </label>
                    <div className="mt-1 col-span-3">
                        <select
                            id="trierPar"
                            name="trierPar"
                            value={filter}
                            onChange={(e)=>{trierPar(e.target.value,movies);localStorage.setItem("trierPar",e.target.value)}}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                        >
                            <option value="date">Dernier ajout</option>
                            <option value="alphabetique">Ordre alphabétique</option>
                            <option value="dateCroissante" >Du plus ancien</option>
                            <option value="dateDecroissante">Du plus récent</option>
                            <option value="noteDecroissante">Pire note</option>
                            <option value="noteCroissante">Meilleure note</option>
                        </select>
                    </div>
                </div>

                { /*count < list.length && 
                    <div className="mt-4" aria-hidden="true">
                        <div className="bg-gray-200 rounded-full overflow-hidden">
                            <div className="h-2 bg-secondary rounded-full" style={{ width: ((count/list.length)*100)+"%" }} />
                        </div>
                    </div>
                */}
                


            <div role="list" className="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-6 xl:gap-x-8 mt-4 ">

            {movies.length == 0 ?
                <Lottie
                    options={optDino}
                    isClickToPauseDisabled={true}
                    height={500}
                    width={window.innerWidth-50}
                />
            :null}
            
                    
                    {movies.map((movie,i)=>{
                        console.log(movie.last_view)

                            if(movie.media_type == "movie"){
                                if ((movie.title.toLowerCase().search(search.toLowerCase()) === -1)) {
                                    return null
                                }
                            }else if(movie.media_type == "tv"){
                                if ((movie.name.toLowerCase().search(search.toLowerCase()) === -1)) {
                                    return null
                                }
                            }
                            return(
                                <Link 
                                key={i}
                                to={{
                                    pathname: (movie.media_type == "movie" ? "/movie/"+movie.id : "/serie/"+movie.id),
                                    state: { search: search,backPath:"/movies",trierPar:filter }
                                }}>
                                    <div   className='group cursor-pointer'>
                                        <div className=" group-hover:opacity-75 block w-full aspect-w-10 aspect-h-15 rounded-t-lg ">
                                            {movie.poster_path ? 
                                                <img src={"https://image.tmdb.org/t/p/w1280"+movie.poster_path} alt="" className=" rounded-t-lg pointer-events-none "/>
                                            :
                                                <div className='border-t border-l border-r border-dashed border-secondary rounded-t-lg'></div>
                                            }
                                        </div>
                                        <div className="bg-white shadow-lg px-2 py-1 rounded-b-lg pointer-events-none border-t">
                                            <p className='text-sm font-medium text-gray-900 truncate '>{movie.title ? movie.title : movie.name}</p>
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-200 text-pink-800">
                                                {
                                                    (movie.media_type == "movie" ? "Film" : "Série")
                                                }
                                            </span>
                                            {movie.release_date ?
                                                (moment(movie.release_date).isAfter(moment()) ? 
                                                <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-200 text-purple-800">
                                                    Prochainement
                                                </span> : null)
                                            :
                                                (moment(movie.first_air_date).isAfter(moment()) ? 
                                                <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-200 text-purple-800">
                                                    Prochainement
                                                </span> : null)
                                            }
                                            <div className="mt-1 flex items-center text-sm text-gray-500 ">
                                                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                                                </svg>
                                                <p className='truncate'>
                                                    {
                                                        (movie.media_type == "movie" ? 
                                                            (movie.release_date ? moment(movie.release_date).format("D MMMM yyyy")  : "Inconnu")
                                                        : 
                                                            (movie.first_air_date ? moment(movie.first_air_date).format("D MMMM yyyy")  : "Inconnu")
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            <div className="mt-1 flex items-center text-sm text-gray-500 ">
                                                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                                </svg>
                                                <p>Vu {movie.view_counter} fois</p>
                                            </div>
                                            <div className="mt-1 flex items-center text-sm text-gray-500 ">
                                                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                                                </svg>
                                                <p>{moment(movie.last_view,"DD-MM-YYYY HH:mm:ss").format("D MMMM yyyy")}</p>
                                            </div>
                                        <div className='flex justify-end'>
                                            <Note note={(Math.round(parseFloat(movie.vote_average) * 10)/10).toFixed(1)} />
                                        </div>
                                        </div>
                                    </div>
                                </Link>
                                    
                            )
                        })
               
                    }
                </div>
                {movies.length != 0 ?
                <div className="relative mb-8 mt-5">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-secondaryLight"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-primary font-medium text-sm text-gray-500">
                        Fin
                    </span>
                </div>
            </div>
            :null}
                </div>
        </>
    )
}
export default List

const Note = ({note}) => {
    //rouge 0-4
    //orange 4-7
    //vert 7-10
    if(note >=0 && note <4 ){
        return(
            <span className="-mb-4 -mr-4 md:-mb-5 md:-mr-5 text-white font-bold text-xs h-7 w-7 md:h-7 md:w-7 rounded-full ring-2 flex justify-center ring-transparent items-center bg-red-400">{note}</span>
        )

    }else if(note >=4 && note <7){
        return(
            <span className="-mb-4 -mr-4 md:-mb-5 md:-mr-5 text-white font-bold text-xs h-7 w-7 md:h-7 md:w-7 rounded-full ring-2 flex justify-center ring-transparent items-center bg-yellow-400">{note}</span>
            )

    }else{
        return(
            <span className="-mb-4 -mr-4 md:-mb-5 md:-mr-5 text-white font-bold text-xs h-7 w-7 md:h-7 md:w-7 rounded-full ring-2 flex justify-center ring-transparent items-center bg-green-400">{note}</span>
            )

    }
}