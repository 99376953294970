import React,{useState,useEffect} from 'react'
import { withRouter,Link,useLocation } from "react-router-dom";
import { FilmIcon,SearchIcon,ChartBarIcon } from '@heroicons/react/outline'

export default withRouter((props)=>{

    let location = useLocation();

    return(
        

        <div className="w-full ">
            {/*<section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">*/} 
            <section id="bottom-navigation" className={(localStorage.getItem('user') == 1 ? " border-red-700 " : localStorage.getItem('user') == 2 ? " border-blue-700 " : " border-secondary " )+" fixed inset-x-0 bottom-0 z-10 bg-primary h-16 border-t "}>
                <div id="tabs" className="flex justify-between">
                    <Link to="/dashboard" className="w-full justify-center inline-block text-center py-2">
                        <FilmIcon className={(location.pathname == "/movies" ? "text-secondaryDark " : "text-secondaryLight " )+ " inline-block py-1 h-8 w-8"} />
                    </Link>
                    <Link to={{
                            pathname: "/search",
                            state: { search: "",filter:{movie:true,tv:true} }
                        }} className="w-full justify-center inline-block text-center py-2">
                        <SearchIcon className={(location.pathname == "/search" ? "text-secondaryDark " : "text-secondaryLight " )+ " inline-block py-1 h-8 w-8"} />
                    </Link>
                    <Link to="/stats" className="w-full justify-center inline-block text-center py-2">
                        <ChartBarIcon className={(location.pathname == "/stats" ? "text-secondaryDark " : "text-secondaryLight " )+ " inline-block py-1 h-8 w-8"} />
                    </Link>
                </div>
            </section>
        </div>
    )
})
